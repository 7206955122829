import React from 'react'
import './footer.css';



function Footer() {
  return (
    <div className='footer'>
      Hosted with Amazon Web Services - AWS
    </div>
  )
}

export default Footer