import React from 'react'
import './separator.css';



function Separator() {
  return (
    <div className='separator'></div>
  )
}

export default Separator