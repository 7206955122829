export const ProjectData=[
    {
        id:1,
        title:"Blogsite - cloudbats.com",
        about:"A blog site sharing knowledge of AWS technologies.",
        tags:["Wordpress", "Amazon Lightsail"],
        demo: 'https://cloudbats.com/',
        // github: 'www.fdlskfm',
        image: require("../../assets/cloudbats.png")

    },
    {
        id:1,
        title:"HR Management System",
        about:"This is a CRUD app to manage a HR database.",
        tags:["GO", "MongoDB"],
        // demo: 'www.fdlskfm',
        github: 'https://github.com/sayedh/go-mongoDB-HRMS',
        image: require("../../assets/HRMS.png")

    },
]