


export const SocialData=[
    {
        platform:"LinkedIn",
        link:"https://www.linkedin.com/in/sm-haque/",
        icon: require("../../assets/icons/linkedin.png"),
    },
    {
        platform:"Github",
        link:"https://github.com/sayedh",
        icon: require("../../assets/icons/github.png"),
    },
    {
        platform:"Discord",
        link:"https://discord.com/channels/smhaque#0909",
        icon: require("../../assets/icons/discord.png"),
    },
];