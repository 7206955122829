export const WorkData=[
    {
        company: "Meta",
        designation: "Network Engineer",
        dateJoining:"Jun 2021",
        dateEnd:"Present",
        companyLogo:"https://brandlogos.net/wp-content/uploads/2021/10/meta-logo.png",
        work:"Maintain and support the network infrastructure via SSH/OOB console at several sites around the globe. Configure and deploy routers/switches/firewall/AP devices from Cisco, Juniper, and Aruba. Respond to service outages and alerts by investigating issues with hardware, ISP, and power outages. Resolve hardware issues with Cisco/Juniper TAC engineers and the onsite support team members via RMA. Support users with requests involving VPN issues, firewall updates, IP allocation, and wifi connectivity issues.",
    },
    {
        company: "Amazon",
        designation: "IT Support",
        dateJoining:"Nov 2020",
        dateEnd:"Jun 2021",
        companyLogo:"https://pngimg.com/uploads/amazon/amazon_PNG5.png",
        work:"Support end users with PCs, thin clients, printers, scanners, mobiles, and tablets via a ticketing system. Resolve issues with Windows 10 / Linux operating systems and reimage with SCCM boot key. Administer network changes via change management on Cisco equipment involving vlan assignments and port config. Conduct IT equipment inventory audits for maintaining adequate spare counts of production need. Perform regular maintenance and security audits on network system racks and deployed IT assets.",
    },

]