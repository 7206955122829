export const SkillsData=[
    {
        type:"Frontend",
        list:[
            {
                name:"AWS",
                icon:<i class="devicon-amazonwebservices-original"></i>,
            },
            {
                name:"ReactJS",
                icon:<i class="devicon-react-original"></i>,
            },
            {
                name:"Javascript",
                icon:<i class="devicon-javascript-plain"></i>,
            },
            {
                name:"HTML",
                icon: <i class="devicon-html5-plain"></i>,
            },
            {
                name:"CSS",
                icon:<i class="devicon-css3-plain"></i>,
            },
        ],
    },

    {
        type:"Languages",
        list:[
            {
                name:"C++",
                icon:<i class="devicon-cplusplus-plain"></i>,
            },
            {
                name:"Python",
                icon: <i class="devicon-python-plain"></i>,
            },
            {
                name:"GO",
                icon: <i class="devicon-go-original-wordmark"></i>,
            },

        ],
    },

    {
        type:"Databases",
        list:[
            {
                name:"Postgres",
                icon:<i class="devicon-postgresql-plain"></i>,
            },
            {
                name:"MySQL",
                icon: <i class="devicon-mysql-plain"></i>,
            },
            {
                name:"MongoDB",
                icon: <i class="devicon-mongodb-plain"></i>,
            },


        ],
    },
]